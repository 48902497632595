import axios from 'axios';
import authHeader from './auth-header';
import { socket } from '@/lib/socket';

const API_URL = process.env.VUE_APP_API_ENDPOINT_STARTER;

const getFileData = async ()=> {
    return axios.get(API_URL + 'files', { headers: await authHeader() });
  }

  const fileOpenedLog = async (obj)=> {
    return socket.emit("CLIENT_PORTAL_FILE_OPENED", obj);

  }

export{
    getFileData,
    fileOpenedLog
}


