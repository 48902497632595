import { reactive } from 'vue' // if needed reactive state for connected
import { io } from 'socket.io-client' // for connection on socket
import { useUserStore } from '@/store/auth.session'
import { notification_receive_functions } from './notifications_functions' // All function regarding notifications
import { logUserSuccessfullLogIn } from '@/services/auth.service'
// import { useGeneralStore } from '@/stores/general_store'

let userStore
export const state = reactive({
    connected: false
})

const URL = process.env.VUE_APP_GENERAL_API
let socket

/**
 * Method that sets up socket connection between client/user and server.
 * The initial header needs to heave user id-s.
 * Need to potentialy upgrade down the line
 */
const setupSocketConnection = async (log_sign_in_flag) => {
  console.log("new socket connection on switch")
    userStore = useUserStore()
    // const general_store = useGeneralStore();
    socket = io(URL, {
        query: {
            
                client_id: userStore.user_profile.uid,
        }
    })

    notification_receive_functions.forEach((single_instance) => {
        socket.on(single_instance.endpoint, single_instance.function) //Go trough each endpoint and hook a function inside.
    }) 
    // if(log_sign_in_flag)
    // {
    //     socket.emit('CLIENT_PORTAL_USER_LOGGED_IN', {client_id: userStore.user_profile.uid})
    // }
    socket.on('CLIENT_PORTAL_CONNECTED', (ret_val) => {
        console.log("Connected to socket", ret_val)
        // setTimeout(()=>{
            if(log_sign_in_flag)
            {
                logUserSuccessfullLogIn({user_id : useUserStore().user_profile.uid})

            }
            console.log("TRY TO LOG IN", log_sign_in_flag)
    
        // },3000)
        // if(log_sign_in_flag)
        // {
            

        // }
        // if(!state.connected)
        // {
        //     socket.emit("CLIENT_PORTAL_USER_LOGGED_IN",{client_id : useUserStore().user_profile.uid})

        // }
        state.connected = true
    })

   



    console.log(socket)
    
}





export {socket, setupSocketConnection }