<template>
  <div>
    <section class="py-36">
      <div class="container px-4 mx-auto">
        <div
          class="relative max-w-lg mx-auto pt-6 pb-16 px-6 md:px-10 lg:px-16 bg-custom-container rounded-xl login-wrapper"
        >
          <a
            class=" top-logo"
            href="#"
          >
            <img
              class="block h-24"
              src="../assets/images/icon-legalleria.svg"
              alt=""
            />
          </a>
          <div class="relative max-w-md mx-auto text-center login-box mt-12">
            <h2 class="text-2xl text-gray-100 font-semibold mb-2">
              Legalleria Client Portal
            </h2>
            <!-- <p class="text-gray-300 font-medium mb-10">
              Molimo unesite svoj registrirani email.
            </p> -->
            <div>
              <div
              v-if="step == 1 "
                class="relative w-full h-14 py-4 px-3 my-8 border border-gray-400 hover:border-white focus-within:border-green-500 rounded-lg"
              >
                <span
                  class="absolute bottom-full left-0 ml-3 -mb-1 transform translate-y-0.5 text-xs font-semibold text-gray-300 px-1 bg-custom-container"
                  >Email</span
                >
                <input
                  class="block w-full outline-none text-center autofill:bg-transparent focus-within:border-0 focus:ring-0 bg-transparent focus:bg-transparent border-0 w-full h-full px-1 text-sm text-gray-100 font-medium"
                  id="signInInput1-1"
                  type="email"
                  v-model="email"
                />
              </div>
     

              <div class="max-w-sm mx-auto my-6" v-if="step == 3 ">
                <div class="flex mb-2 space-x-2 rtl:space-x-reverse">
                  <div v-for="(code, index) in codes" :key="index">
                    <label :for="`code-${index + 1}`" class="sr-only"
                      >Code {{ index + 1 }}</label
                    >
                    <input
                      type="number"
                      maxlength="1"
                      min="0" max="9"
                      :id="`code-${index + 1}`"
                      :ref="`code-${index + 1}`"
                      v-model.number="codes[index]"
                      @input="focusNextInput(index)"
                      @keydown.left.prevent="focusPreviousInput(index)"
                      @keydown.right.prevent="focusNextInput(index)"
                      @keydown.enter.prevent="handleLogin()"
                      class="block w-14 h-14 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      required
                    />
                  </div>
                </div>
                <p
                  id="helper-text-explanation"
                  class="mt-2 text-sm text-gray-500 dark:text-gray-400"
                >
                  Please introduce the 6 digit code we sent via email.
                </p>
              </div>
              <button
                class="block w-full py-4 mb-4 leading-6 text-white font-semibold bg-blue-500 hover:bg-blue-600 rounded-lg transition duration-200"
                @click="requestOTP()"
                v-if="step == 1"
              >
                <span>Prijava</span>
              </button>
              <div v-if="step == 2 " class="relative w-10 mx-auto">
                <div
                  class="w-10 h-10 border-purple-200 border-2 rounded-full"
                ></div>
                <div
                  class="w-10 h-10 border-purple-700 border-t-2 animate-spin rounded-full absolute left-0 top-0"
                ></div>
              </div>
              <button
                class="block w-full py-4 mb-4 leading-6 text-white font-semibold bg-blue-500 hover:bg-blue-600 rounded-lg transition duration-200"
                @click="handleLogin()"
                v-if="step == 3"
              >
                <span>Potvrdi</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, on } from "vue";
import { supabase } from "../services/supabase";

import store from "../store";
import router from "@/router";
import gsap from "gsap";
import { useUserStore } from "@/store/auth.session";
import { requestUserOTP } from "@/services/auth.service";

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const otp_visible = ref(false);
    const userStore = useUserStore();
    const loading = ref(false);
    const codes  = ref(Array(6).fill(''));
    const step = ref(1);

    const requestOTP = async () => {
      step.value = 2;

      try{
      const resp = await requestUserOTP(email.value);

     
        if (resp.status === 200) {
        step.value = 3;
        setTimeout(() => {
          document.getElementById("code-1").select();
        }, 400);
      } else {
        step.value = 1;
      }
      alert(resp.data.message);
      }catch{
        step.value = 1;
      }
      
    };

    const handleLogin = async ()=>{
      step.value = 2;
      const resp = await userStore.loginUser(email.value, codes.value.join(''));
      if(resp.status === 400){
        alert(resp.message);
        codes.value = Array(6).fill('');
        step.value = 3;
        setTimeout(() => {
          document.getElementById("code-1").select();
        }, 400);
      }else{
        location.reload();
      }

    }

    const focusNextInput = (index) => {
      if (index < codes.value.length - 1) {
        document.getElementById(`code-${index+2}`).select();
      }
    }

    const focusPreviousInput = (index) => {
      if (index > 0) {
        document.getElementById(`code-${index}`).select();
      }
    }

    onMounted(() => {
      setTimeout(() => {
        gsap.from(".login-wrapper", {
          duration: 0.7,
          delay: 1,
          backgroundColor: "#1E2431",
          ease: "power1.out",
          force3D: true,
        });
        gsap.from(".top-logo", {
          duration: 1,
          opacity: 0,
          delay: 0,
          ease: "power1.out",
          force3D: true,
        });
        gsap.from(".login-box", {
          duration: 0.7,
          opacity: 0,
          scale: 0.7,
          delay: 2,
          ease: "power1.out",
          force3D: true,
        });
      }, 10);
    });

    return {
      codes,
      email,
      otp_visible,
      password,
      loading,
      step,
      requestOTP,
      focusNextInput,
      focusPreviousInput,
      handleLogin
    };
  },
};
</script>
