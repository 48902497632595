<template>
  <div class="w-full md:1/2 lg:w-1/4 single-stag">
        <div
            class="w- px-3 mb-6"
            :key="file.id"
        >
            <div
                class="relative text-center bg-custom-container rounded-xl h-80 flex flex-col justify-center items-center px-4"
            >
                <img
                    class="block mx-auto mb-5 w-14"
                    :src="returnFileType(file.file_name)"
                    alt=""
                />
                <h4 class="text-white font-bold mb-3 text-lg">
                    {{ file.file_name }}
                </h4>
                <p class="text-gray-300 mb-3 text-sm mx-auto" v-if="canShowCase">
                    <router-link :to="'/case/'+file.case_id" class="hover:underline">{{ file.predmet.ime_predmeta }}</router-link>
                </p>
                <p class="text-gray-300 mb-3 text-sm mx-auto">
                    Dijeljeno s vama: {{ returnTimestamp(file.created_at) }}
                </p>
                <div>
                    <span class="text-gray-300 mb-3 text-sm mr-2">Vrijedi do:</span>
                    <span
                        :class="[
                            'inline-block px-2 py-1 mb-6 text-xs font-medium  rounded-full',
                            isExpired(file.expiration_date_time)
                                ? 'text-red-900 bg-red-500'
                                : ' text-green-500 bg-teal-900',
                        ]"
                    >
                        {{ returnTimestamp(file.expiration_date_time) }}
                    </span>
                </div>
                <div class="flex  -mx-2">

                    <div class="w-full px-2">
                        <a
                            class="flex px-6 py-1 items-center justify-center bg-gray-600 hover:bg-gray-700 text-sm leading-6 font-bold text-white rounded-xl mx-auto"
                            @click="openSingleFile()"
                        >
                            <span class="">Otvori</span>
                        </a>
                    </div>
                    <div class="w-full px-2" v-if="showDetails">
                        <!-- <a
                            class="flex px-6 py-1 items-center justify-center bg-gray-600 hover:bg-gray-700 text-sm leading-6 font-bold text-white rounded-xl mx-auto"
                            href="#"
                            @click="isModalVisible = true"
                        >
                            <span class="">Detalji</span>
                        </a> -->
                        <FileDetailsModal  :file="file" />

                    </div>
                </div>
               
            </div>
        </div>
        <!-- <FileDetailsModal v-if="isModalVisible" :file="file" @close-event="isModalVisible = false "/> -->
      </div>
</template>

<script>
import moment from "moment";
import router from "@/router";
import FileDetailsModal from "./Modals/FileDetailsModal.vue";
import { onMounted, ref } from "vue";
import gsap from "gsap";
import { useUserStore } from '@/store/auth.session';
import { fileOpenedLog } from '@/services/file.service';


export default {
    props: {
        file: {
            type: Object,
            required: true
        },
        canShowCase: {
            type: Boolean,
            required: false
        },
        showDetails: {
            type: Boolean,
            required: false
        }
    },
    setup(props) {
        const isModalVisible = ref(false);
        const user_store = useUserStore();
            const curr_user_id = user_store.user_profile.uid 
        const returnFileType = (file_name) => {
            var idx_ext = file_name.split(".").length - 1;
            var extension = file_name.split(".")[idx_ext].toLowerCase();
            var return_val = require("@/assets/img/doc_icon.svg");
            switch (extension) {
                case "docx":
                    return_val = require("@/assets/img/doc_icon.svg");
                    break;
                case "dotx":
                    return_val = require("@/assets/img/doc_icon.svg");
                    break;
                case "dotm":
                    return_val = require("@/assets/img/doc_icon.svg");
                    break;
                case "pptx":
                    return_val = require("@/assets/img/ppt-icon.png");
                    break;
                case "ppt":
                    return_val = require("@/assets/img/ppt-icon.png");
                    break;
                case "doc":
                    return_val = require("@/assets/img/doc_icon.svg");
                    break;
                case "pdf":
                    return_val = require("@/assets/img/pdf_icon.svg");
                    break;
                case "csv":
                    return_val = require("@/assets/img/xls_icon.svg");
                    break;
                case "xlsx":
                    return_val = require("@/assets/img/xls_icon.svg");
                    break;
                case "jpg":
                    return_val = require("@/assets/img/jpg_icon.svg");
                    break;
                case "jpeg":
                    return_val = require("@/assets/img/jpg_icon.svg");
                    break;
                case "png":
                    return_val = require("@/assets/img/png_icon.svg");
                    break;
                case "folder":
                    return_val = require("@/assets/img/folder_yellow.svg");
                    break;
                default:
                    return_val = require("@/assets/img/dat_icon.svg");
                    break;
            }
            return return_val;
        };
        const isExpired = (dt) => {
            const currentTimestamp = Date.now();
            return currentTimestamp > new Date(dt).getTime();
        };
        const openCase = (case_uid) => {
            router.push("/case/" + case_uid);
        };
        const returnTimestamp = (dt) => {
            moment().locale("hr");
            return moment(dt).format("DD.MM.YYYY.");
        };

        const openSingleFile = () =>{
            window.open(props.file.web_url, "_blank")

            const socket_obj = {
                case_id : props.file.case_id,
                user_id : curr_user_id,
                file_name : props.file.file_name
            }

            fileOpenedLog(socket_obj);
            
        }
        onMounted(() => {
        //     gsap.from(".single-file-transition", {
        //     duration: 1.5,
        //     scale: 0.7,
        //     opacity: 0,
        //     delay: 0.3,
        //     stagger: 0.2,
        //     ease: "power1.out",
        //     force3D: true,
        //   });
        });
        return {
            file: props.file,
            showDetails: props.showDetails == undefined ? true : props.showDetails,
            canShowCase: props.canShowCase,
            isModalVisible,
            returnFileType,
            isExpired,
            openCase,
            returnTimestamp,
            openSingleFile
        };
    },
    components: {  FileDetailsModal }
}
</script>
